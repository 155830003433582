import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private msalService: MsalService) {}

  checkAndRenewToken(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const account = this.msalService.instance.getAllAccounts()[0];
      await this.msalService.instance.initialize();
      await this.msalService.instance.handleRedirectPromise(); // This will no longer throw this error since initialize completed before this was invoked
      if (account) {
        this.msalService.instance.acquireTokenSilent({
          scopes: [`${environment.clientId}/.default`],
          account: account
        }).then((response: AuthenticationResult) => {
          console.log('Token acquired silently', response);
          resolve();
        }).catch(error => {
          console.log('Silent token acquisition failed', error);
          this.msalService.loginRedirect({
            scopes: [`${environment.clientId}/.default`]
          });
          reject(error);
        });
      } else {
        this.msalService.loginRedirect({
          scopes: [`${environment.clientId}/.default`]
        });
        resolve();
      }
    });
  }
}
